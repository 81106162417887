<template>
  <section>
    <div class="container">
      <div class="row">
        <div v-for="article in articles" :key="article.id" class="col-12 col-lg-4">

          <PreviewArticle
              :image-name="article.image_url"
              :title="article.title"
              :description="article.description"
              :date="new Date().toLocaleDateString()"
              :id="String(article.id)"
          />

        </div>
      </div>
    </div>
  </section>
  <div class="bg-lite-white p-5 d-flex justify-content-center align-items-center">
    <Paginator />
  </div>
</template>

<script>
import PreviewArticle from "@/components/news/PreviewArticle";
import Paginator from "@/components/news/Paginator";
import { paginateArticles } from "@/api/news.api";

export default {
name: "News",
  components: { Paginator, PreviewArticle },
  data() {
    return {
      articles: []
    }
  },
  methods: {
    async getArticles(page = 1) {
      const response = await paginateArticles(page);
      if (response.data) {
        this.articles = response.data;
      }

    }
  },
  mounted() {
    this.getArticles();
  }
}
</script>

<style scoped>

</style>