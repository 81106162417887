<template>
  <article class="mb-5 text-center text-md-start p-3" v-if="title && id">
    <img
        @click="redirectTo"
        class="obj-fit-cover w-100 h-100 cursor-pointer"
        :src="imageName"
        :alt="imageAlt"
    />
    <h6 class="date fw-bold mt-3">
      {{ date }}
    </h6>
    <h1
      class="title fw-bold c-blue cursor-pointer"
      @click="redirectTo"
    >
      {{ title }}
    </h1>
    <p class="description">
      {{ description }}
    </p>
    <button class="starter btn bg-blue text-uppercase mt-3">
      <router-link :to="`/news/${id}`">
        Scopri di più
      </router-link>
    </button>
  </article>
</template>
<script>
import redirectMixin from "@/mixins/redirect.mixin.js";

export default {
  name: "PreviewArticle",
  mixins: [ redirectMixin ],
  props: {
    imageName: String,
    imageAlt: String,
    title: String,
    description: String,
    date: String,
  },
}
</script>

<style scoped>
  .title {
    font-size: 2.3rem;
  }
  .title:hover {
    color: #6A6B6B;
    transition: 0.2s ease-in-out;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 90px;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: unset;
  }
</style>